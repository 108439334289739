import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { removeFilter } from "../../../store/slices/filtersSlicer";
import style from "./ActiveFilters.module.scss";
import { RootState } from "../../../store/types";

const ActiveFilters: React.FC = () => {
  const dispatch = useDispatch();
  const activeFilters = useSelector(
    (state: RootState) => state.filters.activeFilters
  );
  const priceFilters = useSelector(
    (state: RootState) => state.filters.priceFilter
  );

  const handleFilterRemove = (
    filterType: string,
    filterValue: string | number
  ) => {
    dispatch(removeFilter({ filterType, filterValue }));
  };
  console.log(activeFilters);
  return (
    <div className={style.activeFilters}>
      {priceFilters.minPrice !== 1600 && (
        <div className={style.activeFilter}>
          Мінімальна ціна: {priceFilters.minPrice}
        </div>
      )}
      {priceFilters.maxPrice !== 20000 && (
        <div className={style.activeFilter}>
          Максимальна ціна: {priceFilters.maxPrice}
        </div>
      )}

      {activeFilters.map((filter, index) => (
        <div
          key={index}
          className={style.activeFilter}
          onClick={() =>
            handleFilterRemove(filter.filterType, filter.filterValue)
          }
        >
          {filter.filterType}: {filter.filterValue}
        </div>
      ))}
    </div>
  );
};

export default ActiveFilters;
