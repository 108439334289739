import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/types";
import style from "./Filters.module.scss";
import Price from "./Price/Price";
import ActiveFilters from "./ActiveFilters/ActiveFilters";
import FilterItem from "./FilterItem/FilterItem";
import URLHandler from "./UrlHandler/UrlHandler";
import { setCategory } from "../../store/slices/categorySlice";
import "./style.css";

interface FilterData {
  name: string;
  options: string[];
}

const Filters: React.FC = () => {
  const dispatch = useDispatch();
  const activeCategory = useSelector(
    (state: RootState) => state.category.selectedCategory
  );

  const filterData: Record<string, FilterData> = {
    capacity: {
      name: "Ємність (Ah)",
      options: ["До 60", "60-100", "100-150", "Більше 150"],
    },
  };

  const handleCategoryChange = (category: string) => {
    dispatch(setCategory(category));
  };

  return (
    <div className={style.container}>
      <p className={style.title}>Фільтри</p>

      <ActiveFilters />

      <form className="form">
        <label htmlFor="01">Всі</label>
        <input
          id="01"
          type="radio"
          name="r"
          value="1"
          checked={activeCategory === "All"}
          onChange={() => handleCategoryChange("All")}
        />
        <label htmlFor="02">Вантажні</label>
        <input
          id="02"
          type="radio"
          name="r"
          value="2"
          checked={activeCategory === "Cargo"}
          onChange={() => handleCategoryChange("Cargo")}
        />
        <label htmlFor="03">Відключення</label>
        <input
          id="03"
          type="radio"
          name="r"
          value="3"
          checked={activeCategory === "Blackout"}
          onChange={() => handleCategoryChange("Blackout")}
        />
      </form>
      <Price />

      {/* Маппінг для фільтрів */}
      <div className={style.filterName}>
        {Object.keys(filterData).map((filterType, index) => (
          <div key={index} className={style.filterContainer}>
            <h5 className={style.filterTitle}>{filterData[filterType].name}</h5>
            <div className={style.filter}>
              {filterData[filterType].options.map((filterValue, subIndex) => (
                <FilterItem
                  key={subIndex}
                  filterType={filterType}
                  filterValue={filterValue}
                />
              ))}
            </div>
          </div>
        ))}
      </div>

      <URLHandler />
    </div>
  );
};

export default Filters;
