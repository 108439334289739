import React from "react";

function ImageGet({ className, src, alt }) {
  const getImage = (imagePath) => {
    try {
      return require(`../../assets/${imagePath}`);
    } catch (err) {
      console.error("Error loading image:", err);
      return null;
    }
  };
  return <img className={className} src={getImage(src)} alt={alt}></img>;
}

export default ImageGet;
