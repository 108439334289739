import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PriceFilter {
  minPrice: number | null;
  maxPrice: number | null;
}

interface Filter {
  filterType: string;
  filterValue: string | number;
}

interface FiltersState {
  activeFilters: Filter[];
  priceFilter: PriceFilter;
}

const initialState: FiltersState = {
  activeFilters: [],
  priceFilter: { minPrice: null, maxPrice: null },
};

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    addFilter(state, action: PayloadAction<Filter>) {
      const newFilter = action.payload;
      const existingFilterIndex = state.activeFilters.findIndex(
        filter => filter.filterType === newFilter.filterType
      );

      if (existingFilterIndex >= 0) {
        state.activeFilters[existingFilterIndex] = newFilter;
      } else {
        state.activeFilters.push(newFilter);
      }
    },
    removeFilter(state, action: PayloadAction<Filter>) {
      state.activeFilters = state.activeFilters.filter(
        filter =>
          filter.filterType !== action.payload.filterType ||
          filter.filterValue !== action.payload.filterValue
      );
    },
     setPriceFilter(state, action: PayloadAction<PriceFilter>) {
      const { minPrice, maxPrice } = action.payload;

      state.priceFilter = { minPrice, maxPrice };
    },
  },
});

export const { addFilter, removeFilter, setPriceFilter } = filtersSlice.actions;
export default filtersSlice.reducer;
export type State = FiltersState;