import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addFilter, removeFilter } from "../../../store/slices/filtersSlicer";
import style from "./FilterItem.module.scss";
import { RootState } from "../../../store/types";
import "./style.css";

interface FilterItemProps {
  filterType: string;
  filterValue: string;
}

const FilterItem: React.FC<FilterItemProps> = ({ filterType, filterValue }) => {
  const dispatch = useDispatch();
  const activeFilters = useSelector(
    (state: RootState) => state.filters.activeFilters
  );

  const isChecked = activeFilters.some(
    (filter: any) =>
      filter.filterType === filterType && filter.filterValue === filterValue
  );

  const handleCheckboxChange = () => {
    if (isChecked) {
      dispatch(removeFilter({ filterType, filterValue }));
    } else {
      dispatch(addFilter({ filterType, filterValue }));
    }
  };

  return (
    <div className={style.filterItem}>
      <label className={style.container}>
        <label className="container">
          <input
            type="checkbox"
            data-value={filterValue}
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <svg viewBox="0 0 64 64" height="1em" width="1em">
            <path
              d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16"
              pathLength="575.0541381835938"
              className="path"
            ></path>
          </svg>
        </label>
        <span className={style.checkmark}>{filterValue}</span>
      </label>
    </div>
  );
};

export default FilterItem;
