// const importImages = (folder, count) => {
//   const images = [];
//   for (let i = 1; i <= count; i++) {
//     images.push(require(`../../assets/photo/${folder}/${i}.webp`));
//   }
//   return images;
// };

export const productData = [
  {
    id: 37,
    name: "Акумулятор Monbat High Cycling Carbon 6СТ-100CG (12HVR100CG)",
    img: "37.png",
    price: 10200,
    capacity: 100,
    startingCurrent: "",
    positiveTerminal: "Зліва",
    voltage: 12,
    caseMadeIn: "Європейські",
    length: 400,
    width: 110,
    height: 286,
    weight: 99,
    warrantyPeriod: "1 рік",
  },
  {
    id: 0,
    name: "Автомобільний акумулятор WESTA 6CT-50 АЗ АзЕ Standard",
    img: "0.jpg",
    price: 1600,
    capacity: 50,
    startingCurrent: 420,
    positiveTerminal: "Зліва",
    voltage: 12,
    caseMadeIn: "Європейські",
    weight: 12.8,
    length: 207,
    height: 175,
    warrantyPeriod: "1 рік",
  },
  {
    id: 1,
    name: "Автомобільний акумулятор WESTA 6CT-60 АЗ АзЕ standard",
    img: "1.jpg",
    price: 1980,
    capacity: 60,
    startingCurrent: 580,
    positiveTerminal: "Зліва",
    voltage: 12,
    caseMadeIn: "Європейські",
    weight: 14,
    length: 242,
    height: 175,
    warrantyPeriod: "1 рік",
  },
  {
    id: 2,
    name: "Автомобільний акумулятор WESTA 6CT-75 АзЕ standard",
    img: "2.jpg",
    price: 2580,
    capacity: 75,
    startingCurrent: 680,
    positiveTerminal: "Справа",
    voltage: 12,
    caseMadeIn: "Європейські",
    weight: 21,
    length: 276,
    height: 190,
    warrantyPeriod: "1 рік",
  },
  {
    id: 3,
    name: "Автомобільний акумулятор WESTA 6CT-100 А3 АзЕ standard",
    img: "3.jpg",
    price: 3400,
    capacity: 100,
    startingCurrent: 840,
    positiveTerminal: "Справа",
    voltage: 12,
    caseMadeIn: "Європейські",
    weight: 24.4,
    length: 353,
    height: 190,
    warrantyPeriod: "1 рік",
  },
  {
    id: 4,
    name: "Вантажний акумулятор Westa Standard 6CT-140Ah Аз",
    img: "4.jpg",
    price: 5100,
    capacity: 140,
    startingCurrent: 900,
    positiveTerminal: "Зліва",
    voltage: 12,
    caseMadeIn: "Європейські",
    weight: 24.4,
    length: 513,
    height: 233,
    warrantyPeriod: "1 рік",
  },
  {
    id: 5,
    name: "Вантажний акумулятор Westa Standard 6CT-190Ah Аз",
    img: "5.jpg",
    price: 6150,
    capacity: 190,
    startingCurrent: 1250,
    positiveTerminal: "Зліва",
    voltage: 12,
    caseMadeIn: "Європейські",
    length: 518,
    width: 240,
    height: 242,
    warrantyPeriod: "1 рік",
  },
  {
    id: 6,
    name: "Акумулятор автомобільний Westa 6CT-50 Аз Premium",
    img: "6.jpg",
    price: 1870,
    capacity: 50,
    startingCurrent: 480,
    positiveTerminal: "Зліва",
    voltage: 12,
    caseMadeIn: "Європейські",
    length: 207,
    width: 175,
    height: 175,
    warrantyPeriod: "1 рік",
  },
  {
    id: 7,
    name: "Акумулятор автомобільний Westa 6СТ-60 Аз Premium",
    img: "7.jpg",
    price: 2150,
    capacity: 60,
    startingCurrent: 600,
    positiveTerminal: "Зліва",
    voltage: 12,
    caseMadeIn: "Європейські",
    length: 242,
    width: 175,
    height: 175,
    warrantyPeriod: "1 рік",
  },
  {
    id: 8,
    name: "Автомобільний акумулятор Westa 6СТ-65 Аз Premium",
    img: "8.jpg",
    price: 2350,
    capacity: 65,
    startingCurrent: 630,
    positiveTerminal: "Зліва",
    voltage: 12,
    caseMadeIn: "Європейські",
    length: 242,
    width: 175,
    height: 190,
    warrantyPeriod: "1 рік",
  },
  {
    id: 9,
    name: "Автомобільний акумулятор Westa 6CT-74 Premium (0)",
    img: "9.jpg",
    price: 2620,
    capacity: 74,
    startingCurrent: 720,
    positiveTerminal: "Справа",
    voltage: 12,
    caseMadeIn: "Європейські",
    length: 278,
    width: 175,
    height: 190,
    weight: 19.5,
    warrantyPeriod: "1 рік",
  },
  {
    id: 10,
    name: "Автомобільний акумулятор WESTA 6CT-100 А АзЕ",
    img: "10.jpg",
    price: 3800,
    capacity: 100,
    startingCurrent: 850,
    positiveTerminal: "Справа",
    voltage: 12,
    caseMadeIn: "Європейські",
    length: 373,
    width: 175,
    height: 190,
    warrantyPeriod: "1 рік",
  },
  {
    id: 11,
    name: "Автомобільний акумулятор Westa Premium 6CT-140",
    img: "11.jpg",
    price: 5100,
    capacity: 140,
    startingCurrent: 900,
    positiveTerminal: "Справа",
    voltage: 12,
    caseMadeIn: "Європейські",
    length: 513,
    width: 189,
    height: 223,
    warrantyPeriod: "1 рік",
  },
  {
    id: 12,
    name: "Автомобільний акумулятор Westa Premium 6CT-192",
    img: "12.jpg",
    price: 6500,
    capacity: 192,
    startingCurrent: 1350,
    positiveTerminal: "Справа",
    voltage: 12,
    caseMadeIn: "Європейські",
    length: 513,
    width: 223,
    height: 223,
    warrantyPeriod: "1 рік",
  },
  {
    id: 13,
    name: "Автомобільний акумулятор Westa 6CT-225",
    img: "13.jpg",
    price: 8200,
    capacity: 225,
    startingCurrent: 1500,
    positiveTerminal: "Зліва",
    voltage: 12,
    caseMadeIn: "Європейські",
    length: 514,
    width: 276,
    height: 242,
    weight: 57,
    warrantyPeriod: "1 рік",
  },
  {
    id: 14,
    name: "Автомобільний акумулятор Westa JIS Asia 6CT-45Аh АзЕ",
    img: "14.jpg",
    price: 1850,
    capacity: 45,
    startingCurrent: 390,
    positiveTerminal: "Справа",
    voltage: 12,
    caseMadeIn: "Європейські",
    length: 234,
    height: 220,
    weight: 12.6,
    warrantyPeriod: "1 рік",
  },
  {
    id: 15,
    name: "Автомобільний акумулятор Westa 6CT-65 АзЕ ASIA EFB",
    img: "15.jpg",
    price: 2320,
    capacity: 65,
    startingCurrent: 600,
    positiveTerminal: "Справа",
    voltage: 12,
    caseMadeIn: "Європейські",
    length: 232,
    width: 175,
    height: 220,
    weight: 16.5,
    warrantyPeriod: "1 рік",
  },
  {
    id: 16,
    name: "Автомобільний акумулятор Westa 6СТ-75 Аз ASIA EFB",
    img: "16.jpg",
    price: 2750,
    capacity: 75,
    startingCurrent: 700,
    positiveTerminal: "Зліва",
    voltage: 12,
    caseMadeIn: "Європейські",
    length: 175,
    height: 220,
    weight: 18.5,
    warrantyPeriod: "1 рік",
  },
  {
    id: 17,
    name: "Автомобільний акумулятор Westa 6CT-100 АзЕ ASIA EFB",
    img: "17.jpg",
    price: 3600,
    capacity: 100,
    startingCurrent: 850,
    positiveTerminal: "Зліва",
    voltage: 12,
    caseMadeIn: "Європейські",
    length: 303,
    width: 175,
    height: 225,
    weight: 22.8,
    warrantyPeriod: "1 рік",
  },
  {
    id: 18,
    name: "Автомобільний акумулятор WESTA 6CT-63 А АзЕ RED EFB Start-Stop",
    img: "18.jpg",
    price: 2400,
    capacity: 63,
    startingCurrent: 620,
    positiveTerminal: "Зліва",
    voltage: 12,
    caseMadeIn: "Європейські",
    length: 242,
    width: 175,
    height: 175,
    weight: 19,
    warrantyPeriod: "1 рік",
  },
  {
    id: 19,
    name: "Автомобільний акумулятор WESTA 6CT-78 А АзЕ RED EFB Start-Stop",
    img: "19.jpg",
    price: 3000,
    capacity: 78,
    startingCurrent: 750,
    positiveTerminal: "Справа",
    voltage: 12,
    caseMadeIn: "Європейські",
    length: 276,
    width: 175,
    height: 190,
    weight: 19,
    warrantyPeriod: "1 рік",
  },
  {
    id: 20,
    name: "Автомобільний акумулятор WESTA 6CT-110 А АзЕ RED EFB Start-Stop",
    img: "20.jpg",
    price: 3900,
    capacity: 110,
    startingCurrent: 920,
    positiveTerminal: "Справа",
    voltage: 12,
    caseMadeIn: "Європейські",
    length: 353,
    width: 175,
    height: 190,
    weight: 26,
    warrantyPeriod: "1 рік",
  },
  {
    id: 21,
    name: "Акумулятор Uno 6CT-60 АзЕ",
    img: "21.jpg",
    price: 1620,
    capacity: 60,
    startingCurrent: 480,
    positiveTerminal: "Зліва",
    voltage: 12,
    caseMadeIn: "Європейські",
    length: 242,
    width: 175,
    height: 190,
    weight: 26,
    warrantyPeriod: "1 рік",
  },
  {
    id: 22,
    name: "Вантажний акумулятор UNO 6CT-140Ah Аз",
    img: "22.jpg",
    price: 4250,
    capacity: 140,
    startingCurrent: 900,
    positiveTerminal: "Справа",
    voltage: 12,
    caseMadeIn: "Європейські",
    length: 513,
    width: 189,
    height: 217,
    weight: 35.2,
    warrantyPeriod: "1 рік",
  },
  {
    id: 23,
    name: "Вантажний акумулятор UNO 6CT-190Ah Аз",
    img: "23.jpg",
    price: 5320,
    capacity: 190,
    startingCurrent: 1250,
    positiveTerminal: "Справа",
    voltage: 12,
    caseMadeIn: "Європейські",
    length: 513,
    width: 218,
    height: 223,
    weight: 35.2,
    warrantyPeriod: "1 рік",
  },

  {
    id: 26,
    name: "Автомобільний акумулятор Extreme 6CT-74 АзЕ",
    img: "26.jpg",
    price: 2630,
    capacity: 74,
    startingCurrent: 720,
    positiveTerminal: "Зліва",
    voltage: 12,
    caseMadeIn: "Європейські",
    length: 175,
    width: 276,
    height: 190,
    weight: 19.5,
    warrantyPeriod: "1 рік",
  },
  {
    id: 27,
    name: "Автомобільний акумулятор Extreme 6CT-100Аh АзЕ",
    img: "27.jpg",
    price: 3550,
    capacity: 100,
    startingCurrent: 850,
    positiveTerminal: "Справа",
    voltage: 12,
    caseMadeIn: "Європейські",
    length: 353,
    width: 175,
    height: 190,
    weight: 22.5,
    warrantyPeriod: "1 рік",
  },

  {
    id: 29,
    name: "Автомобільний акумулятор Extreme 6CT-78Аh EFB АзЕ",
    img: "29.jpg",
    price: 2670,
    capacity: 78,
    startingCurrent: 750,
    positiveTerminal: "Справа",
    voltage: 12,
    caseMadeIn: "Європейські",
    length: 276,
    width: 175,
    height: 175,
    weight: 18,
    warrantyPeriod: "1 рік",
  },

  {
    id: 31,
    name: "Акумулятор Monbat AGM 6СТ-250Ah (12W250MAXX)",
    img: "monbat.png", // Додайте посилання на зображення
    price: 20000,
    capacity: 250,
    startingCurrent: 0, // Якщо значення невідоме
    positiveTerminal: "Праворуч",
    voltage: 12,
    caseMadeIn: "Болгарія",
    length: 557,
    width: 125,
    height: 316,
    weight: 59.2,
    warrantyPeriod: "1 рік",
  },
  {
    id: 32,
    name: "Exide AGM start-stop 80Ah 800A 12V Evro",
    img: "32.png", // Додайте посилання на зображення
    price: 7000,
    capacity: 80,
    startingCurrent: 800,
    positiveTerminal: "Справа",
    voltage: 12,
    caseMadeIn: "Європейські",
    length: 315,
    width: 175,
    height: 190,
    weight: null, // Вага не вказана
    warrantyPeriod: "1 рік",
  },
  {
    id: 33,
    name: "Акумулятор EXIDE 70 (760 А) AGM Start-Stop Євро",
    img: "33.png", // Додайте посилання на зображення
    price: 6500,
    capacity: 70,
    startingCurrent: 760,
    positiveTerminal: "Справа",
    voltage: 12,
    caseMadeIn: "Європейські",
    length: 278,
    width: 175,
    height: 190,
    weight: null, // Вага не вказана
    warrantyPeriod: "1 рік",
  },
  {
    id: 34,
    name: "Акумулятор BOSCH 180 А T5 (1000А) Євро",
    img: "34.png", // Додайте посилання на зображення
    price: 11200,
    capacity: 180,
    startingCurrent: 1000,
    positiveTerminal: "Зліва",
    voltage: 12,
    caseMadeIn: "Європейські",
    length: 513,
    width: 223,
    height: 190,
    weight: null, // Вага не вказана
    warrantyPeriod: "1 рік",
  },
  {
    id: 35,
    name: "Гелієвий акумулятор MONBAT 12HVR200CG 200Ah",
    img: "Monbat2.png", // Додайте посилання на зображення
    price: 16000,
    capacity: 200,
    startingCurrent: "",
    positiveTerminal: "Зліва",
    voltage: 12,
    caseMadeIn: "Європейські",
    length: 557,
    width: 125,
    height: 190,
    weight: 59.2,
    warrantyPeriod: "1 рік",
  },
  {
    id: 36,
    name: "Вантажний акумулятор Westa Standard 6CT-200Ah Аз (WST200)",
    img: "36.png",
    price: 7100,
    capacity: 200,
    startingCurrent: "",
    positiveTerminal: "Зліва",
    voltage: 12,
    caseMadeIn: "Європейські",
    length: 557,
    width: 125,
    height: 190,
    weight: 59.2,
    warrantyPeriod: "1 рік",
  },
];
