import React from "react";
import { useSelector } from "react-redux";
import Item from "./Item/Item";
import style from "./ItemsList.module.scss";

const ItemsList = () => {
  const products = useSelector((state) => state.products.items);
  const activeFilters = useSelector((state) => state.filters.activeFilters);
  const priceFilter = useSelector((state) => state.filters.priceFilter);
  const currentCategory = useSelector(
    (state) => state.category.selectedCategory
  );

  const filterProducts = (products) => {
    return products.filter((product) => {
      // Перевірка відповідності категорії
      const matchesCategory = filterByCategory(product);

      // Перевірка відповідності ціновому діапазону, якщо ціни задані
      const matchesPrice =
        (!priceFilter.minPrice || product.price >= priceFilter.minPrice) &&
        (!priceFilter.maxPrice || product.price <= priceFilter.maxPrice);

      // Перевірка відповідності фільтрам
      const matchesFilters = activeFilters.every((filterTypeGroup) => {
        const filtersOfSameType = activeFilters.filter(
          (filter) => filter.filterType === filterTypeGroup.filterType
        );

        // Якщо є кілька фільтрів одного типу (наприклад, кілька значень ємності)
        return filtersOfSameType.some((filter) => {
          switch (filter.filterType) {
            case "capacity":
              return filter.filterValue === getCapacityRange(product.capacity);
            case "coldCrankingAmps":
              return (
                filter.filterValue ===
                getCrankingAmpsRange(product.coldCrankingAmps)
              );
            case "polarity":
              return filter.filterValue === product.polarity;
            default:
              return true;
          }
        });
      });

      // Повертаємо продукти, що відповідають всім фільтрам
      return matchesCategory && matchesPrice && matchesFilters;
    });
  };

  const filterByCategory = (product) => {
    if (currentCategory === "All") {
      return true; // Всі продукти
    }
    if (currentCategory === "Cargo") {
      return product.capacity >= 140 && product.capacity <= 225;
    }
    if (currentCategory === "Blackout") {
      return product.name.includes("EFB") || product.name.includes("Monbat");
    }
    return false; // За замовчуванням не показуємо жоден продукт
  };

  const getCapacityRange = (capacity) => {
    if (capacity <= 60) return "До 60";
    if (capacity <= 100) return "60-100";
    if (capacity <= 150) return "100-150";
    return "Більше 150";
  };

  const getCrankingAmpsRange = (amps) => {
    if (amps <= 500) return "До 500";
    if (amps <= 1000) return "500-1000";
    return "Більше 1000";
  };

  const filteredProducts = filterProducts(products);
  return (
    <div className={style.container}>
      <div className={style.itemsList}>
        {filteredProducts.map((product) => (
          <Item key={product.id} product={product} />
        ))}
        {filteredProducts.length === 0 && (
          <p>Немає товарів, що відповідають фільтрам.</p>
        )}
      </div>
    </div>
  );
};

export default ItemsList;
