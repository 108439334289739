import React, { useState } from "react";
import style from "./CallBottom.module.scss";
import emailjs from "emailjs-com";

const CallBottom = ({ isOpen, onClose }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Конфігурація для відправки повідомлення
    const serviceId = "service_jc5hu6l"; // ID вашого сервісу з EmailJS
    const templateId = "template_l1c7imr"; // ID вашого шаблону з EmailJS
    const userId = "qht2yz6dbIHWWlekw"; // ID вашого користувача з EmailJS

    // Дані, які будуть використані у шаблоні листа
    const templateParams = {
      phoneNumber: phoneNumber,
    };

    // Відправка листа за допомогою EmailJS
    emailjs
      .send(serviceId, templateId, templateParams, userId)
      .then((response) => {
        console.log("Email sent successfully:", response.status, response.text);
        // Після відправки успішно закриваємо модальне вікно
        onClose();
        setIsSubmitting(false);
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        setIsSubmitting(false);
      });
  };

  return (
    <div className={style.container}>
      {true && (
        <div className={style.modalOverlay}>
          <div className={style.modal}>
            <button className={style.closeButton} onClick={onClose}>
              &times;
            </button>

            <form className={style.modalForm} onSubmit={handleSubmit}>
              <h2 className={style.modalTitle}>Хочете, зателефонуємо Вам?</h2>
              <input
                type="text"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                placeholder="Ваш номер телефону"
                className={style.phoneInput}
                required
              />
              <button
                type="submit"
                className={style.submitButton}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Відправляється..." : "Зателефонуйте мені"}
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default CallBottom;
