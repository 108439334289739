import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../../../store/store";
import { addFilter, setPriceFilter } from "../../../store/slices/filtersSlicer";
import { setCategory } from "../../../store/slices/categorySlice";

const URLHandler: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  // Selectors to get the current filters from Redux state
  const activeFilters = useSelector(
    (state: RootState) => state.filters.activeFilters
  );
  const priceFilter = useSelector(
    (state: RootState) => state.filters.priceFilter
  );
  const activeCategory = useSelector(
    (state: RootState) => state.category.selectedCategory
  );

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const category = queryParams.get("category") || null;
    const minPrice = queryParams.get("minPrice")
      ? parseFloat(queryParams.get("minPrice")!)
      : null;
    const maxPrice = queryParams.get("maxPrice")
      ? parseFloat(queryParams.get("maxPrice")!)
      : null;

    if (category) {
      dispatch(setCategory(category));
    }
    if (minPrice !== null || maxPrice !== null) {
      dispatch(setPriceFilter({ minPrice, maxPrice }));
    }

    queryParams.forEach((value, key) => {
      if (key !== "category" && key !== "minPrice" && key !== "maxPrice") {
        dispatch(addFilter({ filterType: key, filterValue: value }));
      }
    });
  }, [location.search, dispatch]);

  useEffect(() => {
    const queryParams = new URLSearchParams();

    if (activeCategory) {
      queryParams.set("category", activeCategory);
    }

    if (priceFilter.minPrice !== null) {
      queryParams.set("minPrice", priceFilter.minPrice.toString());
    }

    if (priceFilter.maxPrice !== null) {
      queryParams.set("maxPrice", priceFilter.maxPrice.toString());
    }

    activeFilters.forEach((filter) => {
      if (
        filter.filterType !== "category" &&
        filter.filterType !== "minPrice" &&
        filter.filterType !== "maxPrice"
      ) {
        queryParams.set(filter.filterType, filter.filterValue.toString());
      }
    });

    navigate(`?${queryParams.toString()}`, { replace: true });
  }, [activeFilters, priceFilter, activeCategory, navigate]);

  return null;
};

export default URLHandler;
