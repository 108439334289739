import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProduct } from "../../../types";

interface compareState
{
  items: IProduct[];
}

const initialState: compareState = {
  items: []
}

const compareSlice = createSlice( {
  name: 'compare',
  initialState,
  reducers: {
    addToCompare (state, action: PayloadAction<IProduct>)
    {
      state.items.push(action.payload)
    },
    removeFromCompare ( state, action: PayloadAction<IProduct> )
    {
      state.items = state.items.filter(item => item.id !== action.payload.id)
    }
  }
} )

export const {
  addToCompare, removeFromCompare
} = compareSlice.actions;
export default compareSlice.reducer;
export type State = compareState;