import { createSlice } from "@reduxjs/toolkit";
import { IProduct } from "../../../types/index";
import { productData } from "../data"; 

interface ProductState {
  items: IProduct[];
}

const initialState: ProductState = {
  items: productData, 
};

const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {},
});


export default productSlice.reducer;
export type State = ProductState;