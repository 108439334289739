import React, { useState, useEffect } from "react";
import style from "./CategoriesButton.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { Category, ChevronRight } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { setCategory } from "../store/slices/categorySlice";

const CategoriesButton = ({ closeHeader }) => {
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(false);
  const inputRef = React.useRef(null);

  const handleBlur = () => {
    setIsActive(false);
    console.log("blur");
    closeHeader();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      inputRef.current.blur();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleClick = () => {
    setIsActive(!isActive);
  };

  return (
    <div className={style.container}>
      {isActive && <div className={style.overlay} onClick={handleClick}></div>}
      <Button
        className={style.categories}
        variant="contained"
        startIcon={<Category />}
        onClick={handleClick}
      >
        Категорії
      </Button>
      {isActive && (
        <div className={style.menu}>
          <div className={style.categoryContainer}>
            <Link
              className={style.category}
              to="/catalog"
              onClick={() => {
                dispatch(setCategory("All"));
                handleBlur();
              }}
            >
              Акумулятори
            </Link>
            <ChevronRight className={style.searchIcon} />
          </div>
          <div className={style.categoryContainer}>
            <Link
              className={style.category}
              to="/catalog"
              onClick={() => {
                dispatch(setCategory("Cargo"));
                handleBlur();
              }}
            >
              Вантажні
            </Link>
            <ChevronRight className={style.searchIcon} />
          </div>
          <div className={style.categoryContainer}>
            <Link
              className={style.category}
              to="/catalog"
              onClick={() => {
                dispatch(setCategory("Blackout"));
                handleBlur();
              }}
            >
              Відключення світла та <p>під інвертор</p>
            </Link>
            <ChevronRight className={style.searchIcon} />
          </div>
        </div>
      )}
    </div>
  );
};

export default CategoriesButton;
