import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import emailjs from "emailjs-com";
import { Delete, ShoppingCartCheckout } from "@mui/icons-material";

import style from "./CartPopup.module.scss";
import x from "../../assets/x.svg";

import ImgGet from "../ImgGet/ImgGet";
import { removeItemFromCart, updateQuantity } from "../store/slices/cartSlice";
import { togglePopup } from "../store/slices/cartPopupSlice";
import { RootState } from "../../components/store/store";
import { IProduct } from "../../types/index";

const CartPopup: React.FC = () => {
  const dispatch = useDispatch();
  const cartItems = useSelector((state: RootState) => state.cart.items);
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const handleSubmit = () => {
    const serviceId = "service_jc5hu6l";
    const templateId = "template_l1c7imr";
    const userId = "qht2yz6dbIHWWlekw";

    const templateParams = {
      phoneNumber,
    };

    emailjs
      .send(serviceId, templateId, templateParams, userId)
      .then((response) => {
        console.log("Email sent successfully:", response.status, response.text);
        dispatch(togglePopup());
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });
  };

  const calculateTotalPrice = (): number => {
    return cartItems.reduce(
      (total: number, item: IProduct & { quantity: number }) =>
        total + item.price * item.quantity,
      0
    );
  };

  const handleQuantityChange = (
    item: IProduct & { quantity: number },
    quantity: number
  ) => {
    if (quantity > 0) {
      dispatch(updateQuantity({ id: item.id, quantity }));
    }
  };

  const togglePopupFunc = () => {
    dispatch(togglePopup());
  };

  return (
    <div
      className={style.wrapper}
      onClick={() => {
        togglePopupFunc();
      }}
    >
      <div className={style.popup} onClick={(e) => e.stopPropagation()}>
        <div className={style.top}>
          <h3>Кошик</h3>
          <button onClick={togglePopupFunc}>
            <img src={x} alt="Close" />
          </button>
        </div>
        <div className={style.list}>
          {cartItems.map(
            (item: IProduct & { quantity: number }, index: number) => (
              <div key={index} className={style.item}>
                <div className={style.firstInfo}>
                  <ImgGet
                    className={style.img}
                    src={item.img}
                    alt={item.name}
                  />
                  <span className={style.name}>{item.name}</span>
                </div>

                <div className={style.secondInfo}>
                  <p className={style.price}>
                    {item.price}
                    <span className={style.currency}> ₴</span>
                  </p>
                  <div className={style.quantityControl}>
                    <button
                      onClick={() =>
                        handleQuantityChange(item, item.quantity - 1)
                      }
                    >
                      -
                    </button>
                    <span>{item.quantity}</span>
                    <button
                      onClick={() =>
                        handleQuantityChange(item, item.quantity + 1)
                      }
                    >
                      +
                    </button>
                  </div>
                  <button
                    className={style.delete}
                    onClick={() => {
                      dispatch(removeItemFromCart(item.id));
                    }}
                  >
                    <Delete />
                  </button>
                </div>
              </div>
            )
          )}
        </div>
        <div className={style.orderContainer}>
          <button className={style.continue} onClick={togglePopupFunc}>
            Продовжити покупки
          </button>
          <div className={style.orderButtonContainer}>
            <p>
              {calculateTotalPrice()} <span className={style.currency}> ₴</span>
            </p>
            <Link to="/order" onClick={togglePopupFunc}>
              <button className={style.order}>Замовити</button>
            </Link>
          </div>
        </div>
        <form onSubmit={handleSubmit} className={style.bottom}>
          <div className={style.bottomLeft}>
            <div className={style.bottomIcon}>
              <ShoppingCartCheckout />
            </div>
            <div className={style.bottomInfo}>
              <p>Швидке замовлення</p>
              <p>
                Вкажіть свій номер телефону та найближчим часом наш менеджер
                зв'яжеться з Вами. <br />
                Всі товари будуть додані на замовлення автоматично.
              </p>
            </div>
          </div>
          <div className={style.bottomRight}>
            <input
              className={style.bottomInput}
              type="tel"
              placeholder="Ваш номер телефону"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
            />
            <button type="submit" className={style.order}>
              Замовити
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CartPopup;
