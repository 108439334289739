import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DeliveryOptionState
{
  option: string | null;
}
const initialState: DeliveryOptionState = {
  option: null,
};


const deliveryOptionSlice = createSlice({
  name: 'deliveryOption',
  initialState,
  reducers: {
    setDeliveryOption(state, action: PayloadAction<string | null>) {
      state.option = action.payload;
    },
  },
});


export const { setDeliveryOption } = deliveryOptionSlice.actions;
export default deliveryOptionSlice.reducer;
export type State = DeliveryOptionState;