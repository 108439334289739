import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProduct } from "../../../types";

interface ProductState
{
  product: IProduct | null;
}

const initialState: ProductState = {
  product: null,
}

const productSlice = createSlice( {
  name: 'activeProduct',
  initialState,
  reducers: {
    setProduct ( state, actions: PayloadAction<IProduct | null> )
    {
      state.product = actions.payload;
    },
 
  }
} )

export const { setProduct } = productSlice.actions;
export default productSlice.reducer;
export type State = ProductState;