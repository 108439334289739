import React, { ChangeEvent, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import style from "./Price.module.scss";
import { setPriceFilter } from "../../../store/slices/filtersSlicer";
import { IProduct } from "../../../../types";
import { RootState } from "../../../store/store";

function Price() {
  const dispatch = useDispatch();
  const data = useSelector((state: RootState) => state.products.items);
  const priceFilter = useSelector(
    (state: RootState) => state.filters.priceFilter
  );

  const prices = data
    .map((product: IProduct) => parseFloat(product.price.toString()))
    .filter((price: number) => !isNaN(price));

  const minPriceDefault = Math.min(...prices);
  const maxPriceDefault = Math.max(...prices);

  const [minPrice, setMinPrice] = useState(
    priceFilter.minPrice ?? minPriceDefault
  );
  const [maxPrice, setMaxPrice] = useState(
    priceFilter.maxPrice ?? maxPriceDefault
  );

  const priceGap = 1;

  useEffect(() => {
    if (minPrice !== null && maxPrice !== null) {
      dispatch(setPriceFilter({ minPrice, maxPrice }));
    }
  }, [minPrice, maxPrice, dispatch]);

  const handlePriceInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === "input-min") {
      const newMinPrice = parseInt(value, 10);
      if (newMinPrice + priceGap <= maxPrice) {
        setMinPrice(newMinPrice);
      }
    } else if (name === "input-max") {
      const newMaxPrice = parseInt(value, 10);
      if (newMaxPrice - priceGap >= minPrice) {
        setMaxPrice(newMaxPrice);
      }
    }
  };

  const calculateLeft = (
    ((minPrice - minPriceDefault) / (maxPriceDefault - minPriceDefault)) *
    100
  ).toFixed(2);

  const calculateRight = (
    ((maxPriceDefault - maxPrice) / (maxPriceDefault - minPriceDefault)) *
    100
  ).toFixed(2);

  return (
    <div className={style.wrapper}>
      <div>
        <h5>Ціна</h5>
      </div>
      <div className={style.priceInput}>
        <div className={style.field}>
          <input
            type="number"
            className={style["input-min"]}
            value={minPrice}
            onChange={handlePriceInputChange}
            name="input-min"
          />
        </div>
        <div className={style.separator}>-</div>
        <div className={style.field}>
          <input
            type="number"
            name="input-max"
            className={style["input-max"]}
            value={maxPrice}
            onChange={handlePriceInputChange}
          />
        </div>
      </div>
      <div className={style.slider}>
        <div
          className={style.progress}
          style={{ left: `${calculateLeft}%`, right: `${calculateRight}%` }}
        ></div>
      </div>
      <div className={style["range-input"]}>
        <input
          type="range"
          className={style["range-min"]}
          min={minPriceDefault}
          max={maxPriceDefault}
          value={minPrice}
          step={priceGap}
          onChange={(e) => {
            if (parseInt(e.target.value, 10) < maxPrice) {
              setMinPrice(parseInt(e.target.value, 10));
            }
          }}
        />
        <input
          type="range"
          className={style["range-max"]}
          min={minPriceDefault}
          max={maxPriceDefault}
          value={maxPrice}
          step={priceGap}
          onChange={(e) => {
            if (parseInt(e.target.value, 10) > minPrice) {
              setMaxPrice(parseInt(e.target.value, 10));
            }
          }}
        />
      </div>
    </div>
  );
}

export default Price;
