import React, { useState, useRef, useEffect } from "react";
import style from "./Header.module.scss";
import logo from "../../assets/logo.png";
import { Balance, ShoppingCart, Menu, Close } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SearchBar from "../SearchBar/SearchBar.jsx";
import ImgGet from "../ImgGet/ImgGet.jsx";
import CategoriesButton from "../CategoriesButton/CategoriesButton.jsx";

function Header() {
  const cartData = useSelector((state) => state.cart.items);
  const cartLength = useSelector((state) => state.cart.items.length);
  const comparisonLength = useSelector((state) => state.compare.items.length);
  const [isCartModalOpen, setIsCartModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const modalRef = useRef(null);
  const cartRef = useRef(null);
  const modalRefAdaptive = useRef(null);
  function handleClickOutside(event) {
    if (
      !modalRef.current.contains(event.target) &&
      !modalRefAdaptive.current.contains(event.target)
    ) {
      setIsCartModalOpen(false);
    }
  }

  useEffect(() => {
    if (isCartModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isCartModalOpen]);

  const truncateString = (str, num) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  };

  const calculateTotalPrice = () => {
    return cartData.reduce((total, item) => total + item.price, 0);
  };

  const onHeaderClose = () => {
    console.log("asdadsd");
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        <Link to={"/"} className={style.logoContainer}>
          <img className={style.logoImg} src={logo} alt="Logo" />
          <div className={style.logoTextContainer}>
            <span className={style.logoText}>WMaccum</span>
          </div>
          <p className={style.logoDesc}>Офіційні дилери Westa та Monbat</p>
        </Link>
        <CategoriesButton
          closeHeader={() => {
            onHeaderClose();
          }}
        />
        <SearchBar
          closeHeader={() => {
            onHeaderClose();
          }}
        />

        <div className="">
          {comparisonLength > 0 ? (
            <Link to={"/compare"} className={style.comparison}>
              <Balance className={style.icon} />
              {comparisonLength > 0 && (
                <p className={style.comparisonNumber}>{comparisonLength}</p>
              )}
            </Link>
          ) : (
            ""
          )}

          <span className={style.cartContainer}>
            <button
              ref={cartRef}
              className={style.cart}
              onClick={(e) => {
                e.stopPropagation();
                setIsCartModalOpen(!isCartModalOpen);
              }}
            >
              <ShoppingCart className={style.icon} />
              {cartLength > 0 && (
                <p className={style.cartNumber}>{cartLength}</p>
              )}
            </button>
            {isCartModalOpen && (
              <div
                className={style.modal}
                ref={modalRef}
                onClick={(e) => e.stopPropagation()}
              >
                <h2>Кошик</h2>
                <div className={style.modalList}>
                  {cartData.map((item, index) => (
                    <div
                      className={`${style.modalItem} ${
                        index !== cartData.length - 1
                          ? style.modalItemMargin
                          : ""
                      }`}
                      key={index}
                    >
                      <ImgGet
                        className={style.modalImg}
                        src={item.img}
                        alt=""
                      />
                      <div className="">
                        <p className={style.modalName}>
                          {truncateString(item.name, 20)}
                        </p>
                      </div>
                      <p>
                        {item.price} <span className={style.currency}> ₴</span>
                      </p>
                    </div>
                  ))}
                </div>
                <div className={style.modalBottom}>
                  <p className={style.currency}>Кошик</p>
                  <p>{calculateTotalPrice()} ₴</p>
                  <Link
                    to="/order"
                    onClick={() => {
                      setIsCartModalOpen(false);
                    }}
                    className={style.modalOrder}
                  >
                    Оформити замовлення
                  </Link>
                </div>
              </div>
            )}
          </span>
        </div>
      </div>
      <div className={style.adaptiveContainer}>
        <div className={style.adaptiveLogo}>
          <Link to={"/"} className={style.logoContainer}>
            <img className={style.logoImg} src={logo} alt="Logo" />
            <div className={style.logoTextContainer}>
              <span className={style.logoText}>WMaccum</span>
            </div>
            <p className={style.logoDesc}>Офіційні дилери Westa та Monbat</p>
          </Link>
        </div>

        <div
          className={style.adaptiveMenu}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          {isMenuOpen ? (
            <Close className={style.adaptiveIcon} />
          ) : (
            <Menu className={style.adaptiveIcon} />
          )}
        </div>

        <nav
          className={style.adaptiveNav}
          style={{ display: isMenuOpen ? "flex" : "none" }}
        >
          <CategoriesButton
            closeHeader={() => {
              onHeaderClose();
            }}
          />
          <SearchBar
            closeHeader={() => {
              onHeaderClose();
            }}
          />
          <Link
            to={comparisonLength > 0 ? "/compare" : "#"}
            className={style.adaptiveNavLink}
            onClick={() => {
              if (comparisonLength > 0) {
                setIsMenuOpen(!isMenuOpen);
              }
            }}
          >
            <Balance className={style.icon} />
            {comparisonLength > 0 && (
              <span className={style.comparisonNumber}>{comparisonLength}</span>
            )}
          </Link>

          <div className={style.adaptiveCart}>
            <button
              ref={cartRef}
              className={style.cart}
              onClick={(e) => {
                e.stopPropagation();
                if (cartLength > 0) {
                  setIsCartModalOpen(!isCartModalOpen);
                }
              }}
            >
              <ShoppingCart className={style.icon} />
              {cartLength > 0 && (
                <p className={style.cartNumber}>{cartLength}</p>
              )}
            </button>
            {isCartModalOpen && (
              <div
                className={style.modal}
                ref={modalRefAdaptive}
                onClick={(e) => e.stopPropagation()}
              >
                <h2>Кошик</h2>
                <div className={style.modalList}>
                  {cartData.map((item, index) => (
                    <div
                      className={`${style.modalItem} ${
                        index !== cartData.length - 1
                          ? style.modalItemMargin
                          : ""
                      }`}
                      key={index}
                    >
                      <ImgGet
                        className={style.modalImg}
                        src={item.img}
                        alt=""
                      />
                      <div>
                        <p className={style.modalName}>
                          {truncateString(item.name, 20)}
                        </p>
                      </div>
                      <p>
                        {item.price} <span className={style.currency}> ₴</span>
                      </p>
                    </div>
                  ))}
                </div>
                <div className={style.modalBottom}>
                  <p>Кошик</p>
                  <p>{calculateTotalPrice()} ₴</p>
                  <Link
                    to={cartLength > 0 ? "/order" : "#"}
                    onClick={() => {
                      if (cartLength > 0) {
                        setIsCartModalOpen(false);
                        onHeaderClose();
                      }
                    }}
                    className={style.modalOrder}
                  >
                    Оформити замовлення
                  </Link>
                </div>
              </div>
            )}
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Header;
