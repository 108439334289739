import { configureStore } from "@reduxjs/toolkit";

import productReducer from "./slices/productSlice";
import categoryReducer from "./slices/categorySlice";
import filterReducer from "./slices/filtersSlicer";
import cartReducer from "./slices/cartSlice";
import customerReducer from "./slices/customerSlice";
import compareReducer from "./slices/compareSlice";
import activeItemReducer from "./slices/activeProductSlice";
import cartPopupReducer from "./slices/cartPopupSlice";
import deliveryOptionReducer from "./slices/deliveryOptionSlice";

const store = configureStore({
  reducer: {
    products: productReducer,
    category: categoryReducer,
    filters: filterReducer,
    cart: cartReducer,
    compare: compareReducer,
    customer: customerReducer,
    activeItem: activeItemReducer,
    cartPopup: cartPopupReducer,
    deliveryOption: deliveryOptionReducer,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
