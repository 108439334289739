import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import style from "./GoodsSlider.module.scss";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { togglePopup } from "../store/slices/cartPopupSlice";
import { addItemToCart } from "../store/slices/cartSlice";
import ImageGet from "../ImgGet/ImgGet";

function GoodsSlider({ title }) {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.products.items);

  console.log(data);
  const cartItems = useSelector((state) => state.cart.items);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getTransformValue = () => {
    if (viewportWidth <= 768) {
      return `${currentIndex * 70}vw`;
    } else if (viewportWidth <= 1024) {
      return `${currentIndex * 50}vw`;
    } else {
      return `${currentIndex * 20}vw`;
    }
  };

  const handleAddToCart = (item) => {
    const isItemInCart = cartItems.some((cartItem) => cartItem.id === item.id);

    if (!isItemInCart) {
      dispatch(addItemToCart(item));
    }
  };

  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? data.length - 4 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === data.length - 4 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className={style.wrapper}>
      <div className={style.container}>
        <h3 className={style.title}>{title}</h3>
        <div className={style.slider}>
          <button className={style.prev} onClick={handlePrev}>
            &lt;
          </button>
          <div
            className={style.sliderTrack}
            style={{
              transform: `translateX(-${getTransformValue()})`,
            }}
          >
            {data.map((item, index) => (
              <div className={style.sliderItem} key={index}>
                <Link className={style.link} to={`/product/${item.id}`}>
                  <ImageGet className={style.itemImg} src={item.img} alt="" />
                </Link>
                <Link to={`/product/${item.id}`}>
                  <p className={style.name}>{item.name}</p>
                </Link>

                <div className={style.bottom}>
                  <div className={style.addInfo}>
                    <p className={style.price}>
                      {item.price} <span className={style.iconUAH}>₴</span>
                    </p>
                    <p className={style.inStocks}>В наявності</p>
                  </div>
                  <Button
                    onClick={() => {
                      handleAddToCart(item);
                      dispatch(togglePopup());
                    }}
                    className={style.order}
                    variant="contained"
                  >
                    Замовити
                  </Button>
                </div>
              </div>
            ))}
          </div>
          <button className={style.next} onClick={handleNext}>
            &gt;
          </button>
        </div>
      </div>
    </div>
  );
}

export default GoodsSlider;
